<template>
  <div v-if="!isDeleted && blogItem.quizFlag!='solve'">
    <el-row>
      <el-col
      style="padding: 0px;"
      :xs="{span:24}"
      :md="{span:24}"
      >
    <!-- <el-card class="content_card"> -->
    <CardUi :content="formatTime(new Date(blogItem.createTime).getTime(),'{y}年{m}月{d}日')">
      <div style="overflow: hidden;
      ">
      <el-image
      v-if="blogItem.backgroundImg!=undefined && blogItem.backgroundImg!=''"
      class="blog_image"
      :src="blogItem.backgroundImg"
      fit="cover"
      :preview-src-list="preImgUrlList"
      @click="previewCurrentImg(blogItem.backgroundImg)"
      >
      </el-image>
      </div>
      <div
      :class="blogItem.backgroundImg!=null?'hasImg':'noImg'">
      <p class="blog_heading">{{blogItem.heading?blogItem.heading:'暂无标题'}}</p>
      <!-- <div class="blog_create_time">
        <span class="blog_create_time_name"></span>
        <span class="blog_create_time_content">{{blogItem.createTime}}</span>
      </div> -->
      <p class="blog_synopsis_content">{{blogItem.synopsis?blogItem.synopsis:'暂无描述'}}</p>
      <div class="blog_label">
        <el-tag
        v-for="labelItem in blogItem.labelList"
        class="blog_label_tag"
        size="mini"
        effect="dark"
        color="#F2DDBD"
        >
        {{labelItem.labelName}}
        </el-tag>
      </div>
      <div class="blog_operate" v-if="blogItem!=undefined">
        <el-row>
          <el-col v-if="blogItem.user!=null && blogItem.user.username!='general' && blogItem.user.username!=$store.state.user.user.username"
          :md="{span:12,offset:0}":xs="8" class="blog_operate_col" >
            <button v-if="blogItem.user!=null" class="blog_operate_user link_button"
            @click.stop="link_to('/user',null,blogItem.user.username)">
              <!-- <router-link tag="a" target="_blank" :to="{path: '/publish', query: {username:blogItem.user.username}}"> -->
              <el-avatar size="small" style="float: left;" :src="blogItem.user.avatar"></el-avatar>
              <el-tooltip class="item" effect="dark" placement="top-end"
              :content="blogItem.user.nickname"
              >
                <span class="blog_operate_username"style="">{{blogItem.user.nickname}}
                </span>
              </el-tooltip>

              <!-- </router-link> -->
            </button>
          </el-col>

          <el-col v-if="blogItem.user!=null && blogItem.user.username!='general' && $store.state.user.user.username!=undefined  &&blogItem.user.username===$store.state.user.user.username"
          :md="{span:12,offset:0}":xs="8" class="blog_operate_col" >
            <el-row style="padding-bottom:10px;">
              <el-col :md="{span:6,offset:0}" :xs="12">
                  <el-button
                  @click.stop="link_to('/publish',blogItem.id,blogItem.user.username)"
                  type="primary" icon="el-icon-edit" circle size="mini"></el-button>
              </el-col>
              <el-col :md="{span:6}" :xs="12">
                <el-button type="danger" icon="el-icon-delete" circle size="mini"
                @click.stop="deleteArticle(blogItem.id)"></el-button>
              </el-col>
            </el-row>
          </el-col>
          <el-col :md="{span:8,offset:blogItem.user!=null?4:0}">
            <PraiseStep :item="blogItem" itemType="blog" :showGlance="false"></PraiseStep>
          </el-col>
<!--          <el-col v-if="blogItem.user===null || blogItem.user.avatar===null"
          :md="{span:12,offset:0}":xs="8" class="blog_operate_col" >
            <div  class="blog_operate_user">
              <el-avatar size="small" style="float: left;" v-if="blogItem.user===null || blogItem.user.avatar===null" :src="defaultAvatarURL"></el-avatar>
              <span class="blog_operate_username" style="">没有用户啊</span>
            </div>
          </el-col> -->
         <!-- <el-col v-if="blogItem.user.username==='general'"
          :md="{span:8,offset:0}" class="blog_operate_col" >
            <button style="border: none;background-color: #D46A8E;border-radius: 10px;padding: 5px;color: #FFFFFF;
            padding-left: 10px;padding-right: 10px;cursor: pointer;
            ">解决</button>
          </el-col> -->
          <!-- <el-col :md="{span:24}">

          </el-col> -->
         </el-row>
      </div>
      </div>
    </CardUi>
    </el-col>
    </el-row>
  </div>
</template>

<script>
  import global from '@/api/global_variable.js'
  import {praiseApi,stepApi} from '@/api/comment'
  import {deleteArticleApi} from '@/api/article'
  import PraiseStep from '@/components/PraiseStep'
  import CardUi from './ui/CardUi'
  import { parseTime,formatTime } from '@/utils/date'
  export default {
    name:'blogCard',
    props:['blog'],
    components: {
      PraiseStep,
      CardUi
    },
    watch: {
      blog(newValue, oldValue) {
        this.blogItem=newValue
      }
    },
    data(){
      return{
        isDeleted:false,
        preImgUrlList:[],
        imgURL:global.imgURL,
        blogItem:this.blog,
        defaultAvatarURL:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      }
    },
    methods:{
      formatTime,
      link_to(path,blogId,username){
        if(blogId==null){
          this.$router.push({path: path, query: {username:username}})
        }else{
          this.$router.push({path: path, query: {blogId:blogId,username:username}})
        }
      },
      previewCurrentImg(preImgUrl){
        this.preImgUrlList=[]
        this.preImgUrlList.push(preImgUrl)
        console.log("当前预览的图片"+this.preImgUrlList)
      },

    deleteArticle(blogId){
      this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteBlog(blogId)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async deleteBlog(blogId){
      let ids=[blogId]
      const res=await deleteArticleApi(ids)
      if(res.data.success){
        this.isDeleted=true
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      }else{
        this.$message({
          message: '服务器异常',
          type: 'error'
        });
      }
    }
    }
  }
</script>

<style  lang="less" scoped>
  .hasImg{
    width: 100%;
    height: 30px;
    margin-top: -30px;
    background-color: #F5F5F5;
    border-top-left-radius: 30px;
    padding-top: 10px;
  }
  .noImg{
    // height: 20px;
    padding-top: 10px;
    height:100%;
  }
  .link_button{
    cursor: pointer;
    border:none;
    background-color: transparent;
    width: 100%;
    height:100%;
    padding:0px
  }
  .content_card{
    flex-wrap: wrap;
    justify-content: start;
    background-color: #ffffff;
    width:100%;
    border:none;
    padding:0px;
   /* border-radius: 5px; */
    transition-property: all;
    transition-duration: 0.5s;
  }
  .content_card:hover{
    box-shadow: 2px 2px 10px #F7F7B8;
  }
  /deep/.el-card__body {
    padding: 0;
    border-radius:0px
  }
  .blog_image{
    z-index: -1;
    width: 100%;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    // border-bottom-right-radius:100px;
  }
  .blog_heading{
    padding-left:10px;
    padding-right: 10px;
    margin:0px;
    margin-bottom: 5px;
    color: #333333;
    font-weight: bold;
    font-size: 20px;
    word-wrap:break-word
    // font-size: 18px;

  }
  .blog_label{
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .blog_label_tag{
    border:none;
    margin-right: 10px;
    color:#ffffff;
    margin-bottom:10px;
  }
  .blog_synopsis_name{
    /* // width: ; */
    color:#212121;
  }
  .blog_synopsis_content{
    word-break: break-all;
    font-size: 13px;
    padding-left: 10px;
    margin:0px;
    margin-bottom: 5px;
    padding-right: 10px;
    color:#8A9FAB;
  }
  .blog_create_time{
    padding-left: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    color:#969799;
  }
  .blog_operate{
    padding-left: 10px;
    // margin-bottom: 10px;
    line-height: 100%;
  }
  .blog_operate_user{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .blog_operate_user a{
    text-decoration: none;
    color:#303133;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .blog_operate_user a:hover{
   color:#A7A8AA;
  }
  .blog_operate_username{
    font-size: 10px;
    margin-left: 5px;
    overflow: hidden;
    float: left;line-height: 30px;
    width: 100px;
    text-overflow: ellipsis;
    text-align: left;
    transition-property: all;
    transition-duration: 0.2s;
    font-weight: bold;
    font-size: 15px;
  }
  .blog_operate_username:hover{
    color:#F2DDBD;
    text-shadow: 0px 0px 2px #F2DDBD;
  }
  .blog_operate_botton{
      font-size: 13px;
      border-radius: 100%;
      border: none;
      color:#969799;
      padding: 0px;
      margin-top:8px;
      background-color: transparent;
      cursor: pointer;
      height:18px;

    }
  .blog_operate_botton_animation{
    font-size: 13px;
    border-radius: 100%;
    border: none;
    color:#969799;
    padding: 0px;
    margin-top:8px;
    background-color: transparent;
    cursor: pointer;
    height:18px;
    animation: showMsg 0.5s;
  }

  @keyframes showMsg
  {
    0%   {font-size: 13px;}
    20%{
      font-size: 14px;
      transform:rotate(-9deg);
      -ms-transform:rotate(-9deg); 	/* IE 9 */
      -moz-transform:rotate(-9deg); 	/* Firefox */
      -webkit-transform:rotate(-9deg); /* Safari 和 Chrome */
      -o-transform:rotate(-9deg); 	/* Opera */
    }
    25%  {
      font-size: 14px;
      transform:rotate(9deg);
      -ms-transform:rotate(9deg); 	/* IE 9 */
      -moz-transform:rotate(9deg); 	/* Firefox */
      -webkit-transform:rotate(9deg); /* Safari 和 Chrome */
      -o-transform:rotate(9deg); 	/* Opera */
      }

    50%  {
      font-size: 15px;
      transform:rotate(-9deg);
      -ms-transform:rotate(-9deg); 	/* IE 9 */
      -moz-transform:rotate(-9deg); 	/* Firefox */
      -webkit-transform:rotate(-9deg); /* Safari 和 Chrome */
      -o-transform:rotate(-9deg); 	/* Opera */
    }
    75%  {
      font-size: 15px;
      transform:rotate(9deg);
      -ms-transform:rotate(9deg); 	/* IE 9 */
      -moz-transform:rotate(9deg); 	/* Firefox */
      -webkit-transform:rotate(9deg); /* Safari 和 Chrome */
      -o-transform:rotate(9deg); 	/* Opera */
    }
    100% {font-size: 13px;}
  }
</style>
