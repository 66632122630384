<template>
  <div class="card" :data-attr="content">
    <div class="content"><slot></slot></div>
  </div>
</template>

<script>
  export default{
    name:'CardUi',
    props:['heading','content'],
    data(){
      return {
      }
    }
  }
</script>

<style scoped>
.card {
 width: 100%;
 height: auto;
 /* min-height: 200px; */
 /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.212); */
 /* box-shadow: 0.3em 0.3em 0.7em #00000015; */
 background: #D6D6D6;
 display: flex;
 border-radius: 5px;
 justify-content: center;
 position: relative;
 transition: all .4s;
 /* color: #f; */
}

.card::before {
 content: attr(data-attr);
 letter-spacing: 0.2em;
 position: absolute;
 bottom: 5px;
 left: 20px;
 color: rgb(51, 51, 51);
 font-size: 0.5em;
 font-weight: 700;
 color: #8D8D8D;
}

.card .content {
 width: 100%;
 height: 100%;
 /* min-height: 200px; */
 border-radius: 5px;
 /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.212); */
 /* box-shadow: 0.3em 0.3em 0.7em #00000015; */
 cursor: pointer;
 z-index: 10;
 transition: all .4s;
 background-color: #F5F5F5;
}

.card:hover .content {
 transform: translateY(-20px);
}
</style>
