<template>
  <div>
    <el-row
    >
      <el-col :md="showGlance?6:12" :xs="6" class="blog_operate_col">
      <!-- <button class="blog_operate_botton"
      @click.stop="praise(item,$event)"
      @animationend='restAnimation($event)'
      ><span class="fa fa-thumbs-o-up"></span> {{item.praise}}️</button> -->
      <ButtonFeedBackUi
      type="praise"
      @click="praise(item,$event)"
      >
        <!-- <svg t="1677231430688" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4041" width="32" height="32"><path d="M171.3 872h55V395h-55c-8.8 0-16 7.2-16 16v445c0 8.9 7.1 16 16 16zM852.4 395H518.8l41.4-104.1c19.9-50-4.6-106.6-54.6-126.5l-30.8-12.2c-8.2-3.2-17.4 0.7-20.7 8.9L361.2 395h-63.8v477h491.2c7.9 0 14.6-5.8 15.7-13.6l64.1-445c1.4-9.7-6.1-18.4-16-18.4z" fill="#231815" p-id="4042"></path></svg>
        </br> -->
        <span class="fa fa-thumbs-o-up"
        style="font-weight: bold;"
        ></span>
        <span style="display: inline-block;
        text-overflow: ellipsis;
        font-size: 13px;
        padding-left: 5px;
        font-weight: bold;
        ">
        {{item.praise>=999?999:item.praise}}️
        </span>
      </ButtonFeedBackUi>
      </el-col>
      <el-col :md="showGlance?6:12":xs="6" class="blog_operate_col">
        <ButtonFeedBackUi
        type="step"
        @click="step(item,$event)"
        >
          <span class="fa fa-thumbs-o-down"
          style="font-weight: bold;"
          ></span>
          <span style="display: inline-block;text-overflow: ellipsis;
          font-size: 13px;
          padding-left: 5px;
          font-weight: bold;
          ">{{item.step>=999?999:item.step}}️</span>
        </ButtonFeedBackUi>
<!--      <button class="blog_operate_botton"
      @click.stop="step(item,$event)"
      @animationend='restAnimation($event)'
      ><i class="fa fa-thumbs-o-down"/> {{item.step}}️</button> -->
      </el-col>
      <el-col v-if="showGlance" :md="6":xs="6" class="blog_operate_col">
        <div style="width: 100%;text-align: center;">
          <span class="fa fa-eye"
          style="color: #D6D6D6;
          font-weight: bold;
          "
          ></span>
          <span style="display: inline-block;text-overflow: ellipsis;
          color: #D6D6D6;
          font-size: 13px;
          padding-left: 5px;
          font-weight: bold;
          ">{{item.glance>=999?999:item.glance}}️</span>
        </div>
      </el-col>
      <!-- <el-col v-if="itemType=='blog'" :span="6":xs="6" class="blog_operate_col">
      <button class="blog_operate_botton"><i class="fa fa-heart-o"/> {{item.collect}}️</button>
      </el-col> -->
     </el-row>
  </div>
</template>

<script>
  import {praiseApi,stepApi} from '@/api/comment'
  import ButtonFeedBackUi from'./ui/ButtonFeedBackUi'
  export default{
    name:'PraiseStep',
    props:['item','itemType','showGlance'],
    components: {
      ButtonFeedBackUi
    },
    data() {
      return {

      }
    },
    methods: {
      //回复样式
      restAnimation(e){
        // e.target.className='blog_operate_botton'
      },
      //点赞
        async praise(item,e) {
        if(this.$store.state.user.user.username!=undefined){
          // e.target.className='blog_operate_botton_animation'
          item.praise+=1
          let query
          if(this.itemType=='blog'){
            query={
              blogId:item.id,
              username:item.user.username,
            }
          }else if(this.itemType=='comment'){
            query={
              commentId:item.id,
              username:item.user.username,
            }
          }
          await praiseApi(query)
        }else{
          this.$message({
            message: '登录才能操作哦',
            type: 'warning'
          });
        }
      },
      //点踩
      async step(item,e) {
        if(this.$store.state.user.user.username!=undefined){
          // e.target.className='blog_operate_botton_animation'
          item.step+=1
          let query
          if(this.itemType=='blog'){
            query={
              blogId:item.id,
              username:item.user.username,
            }
          }else if(this.itemType=='comment'){
            query={
              commentId:item.id,
              username:item.user.username,
            }
          }
          await stepApi(query)
        }else{
          this.$message({
            message: '登录才能操作哦',
            type: 'warning'
          });
        }
      },
    },
  }
</script>

<style scoped>
  .blog_operate_botton{
      font-size: 13px;
      border-radius: 100%;
      border: none;
      color:#969799;
      padding: 0px;
      margin-top:8px;
      background-color: transparent;
      cursor: pointer;
      height:18px;

    }
  .blog_operate_botton_animation{
    font-size: 13px;
    border-radius: 100%;
    border: none;
    color:#969799;
    padding: 0px;
    margin-top:8px;
    background-color: transparent;
    cursor: pointer;
    height:18px;
    animation: showMsg 0.5s;
  }

  @keyframes showMsg
  {
    0%   {font-size: 13px;}
    20%{
      font-size: 14px;
      transform:rotate(-9deg);
      -ms-transform:rotate(-9deg); 	/* IE 9 */
      -moz-transform:rotate(-9deg); 	/* Firefox */
      -webkit-transform:rotate(-9deg); /* Safari 和 Chrome */
      -o-transform:rotate(-9deg); 	/* Opera */
    }
    25%  {
      font-size: 14px;
      transform:rotate(9deg);
      -ms-transform:rotate(9deg); 	/* IE 9 */
      -moz-transform:rotate(9deg); 	/* Firefox */
      -webkit-transform:rotate(9deg); /* Safari 和 Chrome */
      -o-transform:rotate(9deg); 	/* Opera */
      }

    50%  {
      font-size: 15px;
      transform:rotate(-9deg);
      -ms-transform:rotate(-9deg); 	/* IE 9 */
      -moz-transform:rotate(-9deg); 	/* Firefox */
      -webkit-transform:rotate(-9deg); /* Safari 和 Chrome */
      -o-transform:rotate(-9deg); 	/* Opera */
    }
    75%  {
      font-size: 15px;
      transform:rotate(9deg);
      -ms-transform:rotate(9deg); 	/* IE 9 */
      -moz-transform:rotate(9deg); 	/* Firefox */
      -webkit-transform:rotate(9deg); /* Safari 和 Chrome */
      -o-transform:rotate(9deg); 	/* Opera */
    }
    100% {font-size: 13px;}
  }
</style>
